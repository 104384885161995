import { useSelector } from 'react-redux';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { notifyError } from 'helper/utils';
import { useAccountLogout } from './useAccountLogout';
import { handleErrorResponse } from 'helper/utils';

const postApiData = async ({ apiEndpoint, payload, authToken, userTimezone, BASE_URL, isFileUpload }) => {
  const headers = {
    'User-Timezone': userTimezone,
    ...(authToken && { Authorization: `Bearer ${authToken}` }),
    ...(isFileUpload && { 'Content-Type': 'multipart/form-data' }),
  };

  const config = { headers,  withCredentials: true, };

  // If it's a file upload, ensure the payload is in FormData format
  let dataToSend = payload;
  if (isFileUpload) {
    const formData = new FormData();
    for (const key in payload) {
      // Check if the payload contains attachments and if it's an array
      if (Array.isArray(payload[key])) {
        payload[key].forEach((file, index) => {
          formData.append(`${key}[${index}]`, file);
        });
      } else {
        formData.append(key, payload[key]);
      }
    }
    dataToSend = formData;
  }

  const response = await axios.post(`${BASE_URL}${apiEndpoint}`, dataToSend, config);
  return response.data;
};

export function usePostData(apiEndpoint, options = {}) {
  const { user } = useSelector((state) => state.auth);
  const authToken = user?.token;
  const logout = useAccountLogout();
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const mutation = useMutation({
    mutationFn: ({ payload, isFileUpload }) =>
      postApiData({ apiEndpoint, payload, authToken, userTimezone, BASE_URL, isFileUpload }),
    onError: (err) => {
      handleErrorResponse(err, null, notifyError, logout);
    },
    ...options, // Additional options can be passed here
  });

  const postData = (payload, isFileUpload = false) => {
    mutation.mutate({ payload, isFileUpload });
  };

  return {
    postData,
    loading: mutation.isPending,
    error: mutation.error,
    response: mutation.data,
    isSuccess: mutation.isSuccess,
  };
}








// import { useState, useCallback } from 'react';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
// import { notifyError } from 'helper/utils';
// import { useAccountLogout } from './useAccountLogout';
// import { handleErrorResponse } from 'helper/utils';

// export function usePostData(apiEndpoint) {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [response, setResponse] = useState(null);
//   const BASE_URL = process.env.REACT_APP_API_BASE_URL;
//   const {user} = useSelector(state => state.auth);
//   const authToken = user && user.token;
//   const logout = useAccountLogout()
//   const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//   const postData = useCallback(async (payload) => {
//     setLoading(true);
//     setError(null);

//     try {

//       if (authToken !== undefined || authToken !== null) {
//         axios.defaults.headers.common['User-Timezone'] = userTimezone;
//         axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
//       }
      
//       const responseData = await axios.post(BASE_URL+apiEndpoint, payload);
//       setResponse(responseData.data);
//       // Optionally, you can handle success or further actions here.
//     } catch (error) {
//       handleErrorResponse(error, setError, notifyError, logout);
//     } finally {
//       setLoading(false);
//     }
//   }, [authToken, apiEndpoint]);

//   return { postData, loading, error, response };
// }
