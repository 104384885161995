import { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { handleErrorResponse } from 'helper/utils';
import { notifyError } from 'helper/utils';
import { useAccountLogout } from './useAccountLogout';

// export function useFetchData(apiEndpoint) {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [refreshToken, setRefreshToken] = useState(0);
//   const BASE_URL = process.env.REACT_APP_API_BASE_URL;
//   const {user} = useSelector(state => state.auth);
//   const authToken = user && user?.token;
//   const logout = useAccountLogout();
//   const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//   const fetchData = async () => {
//     if(apiEndpoint === null) return {};
//     try {

//       if (authToken !== null) {
//         axios.defaults.headers.common['User-Timezone'] = userTimezone;
//         axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
//       }
      
//       const response = await axios.get(BASE_URL+apiEndpoint);
//       setData(response.data);
//     } catch (err) {
//       handleErrorResponse(err, setError, notifyError, logout);;
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [authToken, apiEndpoint, refreshToken]);

//   // Function to manually trigger a refresh
//   const refreshData = () => {
//     setRefreshToken(previous => previous + 1);
//   }

//   return { data, loading, error, setData, refreshData, fetchData };
// }


const fetchApiData = async ({ queryKey }) => {
  const [apiEndpoint, queryParams, authToken, userTimezone, BASE_URL] = queryKey;

  const config = {
    headers: {
      ...(authToken && { Authorization: `Bearer ${authToken}` }),
      'User-Timezone': userTimezone,
    },
    withCredentials: true,
     params: queryParams,
    // }, // Axios automatically encodes query params
   };

    const response = await axios.get(`${BASE_URL}${apiEndpoint}`, config);
    return response.data;
  };

  export function useFetchData(apiEndpoint, queryParams = {}, options = {}) {
    const { user } = useSelector((state) => state.auth);
    const authToken = user?.token;
    const logout = useAccountLogout();
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Memoize the fetch function to avoid unnecessary re-renders
  const queryKey = useMemo(() => [apiEndpoint, queryParams, authToken, userTimezone, BASE_URL], [
    apiEndpoint,
    queryParams,
    authToken,
    userTimezone,
    BASE_URL
  ]);

  const { data, error, isLoading, refetch, isFetching, isSuccess } = useQuery({
    queryKey,
     queryFn: fetchApiData,  // Use the function directly
     enabled: !!apiEndpoint,  // Ensure query only runs when apiEndpoint is provided
     refetchOnWindowFocus: false,  // Disable refetching on window focus
     staleTime: 5 * 60 * 1000,  // 5 minutes of stale time
     cacheTime: 30 * 60 * 1000,  // 30 minutes of cache time
     retry: 3,  // Retry failed requests up to 3 times
     onError: (err) => {
      handleErrorResponse(err, notifyError, logout);
     },
     ...options,  // Support additional options
  });

  return {
     data,
     loading: isLoading || isFetching,  // Combine isLoading and isFetching states
     error,
     isSuccess,
     refreshData: refetch,  // Method to manually refresh the data
  };
}